import React from 'react';
import { graphql } from 'gatsby';

import { getBioTextWithLocale } from '../../utilities';
import MainLayout from '../../components/MainLayout';
import ArticleAuthorDetails from '../../components/ArticleMarkup/ArticleAuthorDetails';
import ArticleHead from '../../components/ArticleMarkup/ArticleHead';
import ArticleSEO from '../../components/SEO/ArticleSEO';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';
import { AUTHOR_URL, CATEGORIES_URL } from '../../utilities/urlConstants';
import useOnNewActiveLanguage from '../../hooks/useOnNewActiveLanguage';
import useInstagramLazyLoader from '../../hooks/useInstagramLazyLoader';
import parseBlocks from '../../utilities/parseBlocks';
import * as style from './Article.module.scss';

const ArticleTemplate = (props) => {
  const { data, pageContext } = props;
  const { translationsAxios } = pageContext;
  const { wpPost, site, topMenu, bottomMenu, footer, socialMenu } = data;
  const {
    title,
    Lead,
    featuredImage,
    author,
    dateGmt,
    blocks: blocksRaw,
    seo,
    slug,
    categories,
    DisableFeatureImageInPost,
    AdvancedSEOSettings,
  } = wpPost;
  const category = categories?.nodes?.[0];
  const { siteMetadata } = site;
  const { siteUrl } = siteMetadata;
  const advancedSeo = AdvancedSEOSettings.advancedSeoSettings;

  const setNewActiveLanguage = useOnNewActiveLanguage(
    pageContext?.locale,
    translationsAxios
  );

  useInstagramLazyLoader();

  const categoryLink = pathJoinSafeSlashes(CATEGORIES_URL, category?.slug);

  const videoBlock =
    !!DisableFeatureImageInPost?.disableFeatureImageInPost &&
    blocksRaw[0].__typename === 'WpAcfCustomVideoBlock'
      ? blocksRaw[0]
      : null;

  const disableFeatureImg =
    !!DisableFeatureImageInPost?.disableFeatureImageInPost && !!videoBlock;

  const blocks = disableFeatureImg ? blocksRaw.slice(1) : blocksRaw;

  return (
    <MainLayout
      setNewActiveLanguage={setNewActiveLanguage}
      topMenu={topMenu}
      bottomMenu={bottomMenu}
      footer={footer}
      socialMenu={socialMenu}
      hasNoMarginBelow
    >
      <ArticleSEO
        seo={seo}
        advancedSeo={advancedSeo}
        postLocale={wpPost?.language?.locale}
        postUrl={slug}
        authorName={author?.node?.name}
        siteUrl={siteUrl}
        translations={translationsAxios}
      />
      <div className={style.articleContents}>
        <ArticleHead
          title={title}
          lead={Lead?.lead}
          category={category?.name}
          categoryLink={categoryLink}
          imgData={featuredImage?.node?.imgixImage?.gatsbyImageData}
          imgAlt={featuredImage?.node?.altText || featuredImage?.node?.slug}
          imgHoverTitle={
            featuredImage?.node?.title || featuredImage?.node?.slug
          }
          authorImgData={
            author?.node?.hq_avatar?.hqAvatar?.imgixImage?.gatsbyImageData
          }
          authorName={author?.node?.name}
          authorSlug={pathJoinSafeSlashes(AUTHOR_URL, author?.node?.slug)}
          datePostedGmt={dateGmt}
          timeToRead={seo?.readingTime}
          isDisableFeaturedImg={disableFeatureImg}
          videoBlock={videoBlock}
          locale={wpPost?.language?.locale}
        />
        <div className={style.articleBlocks}>
          {parseBlocks(blocks, wpPost?.language?.locale)}
        </div>

        <ArticleAuthorDetails
          name={author?.node?.name}
          details={getBioTextWithLocale(
            author?.node?.multilingual_bio,
            wpPost?.language?.locale
          )}
          imgData={
            author?.node?.hq_avatar?.hqAvatar?.imgixImage?.gatsbyImageData
          }
          slug={pathJoinSafeSlashes(AUTHOR_URL, author?.node?.slug)}
        />
      </div>
    </MainLayout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query (
    $id: String!
    $topMenuId: String!
    $bottomMenuId: String!
    $footerId: String!
    $socialMenuId: String!
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    topMenu: wpMenu(id: { eq: $topMenuId }) {
      ...topMenuFields
    }
    bottomMenu: wpMenu(id: { eq: $bottomMenuId }) {
      ...otherMenuFields
    }
    footer: wpMenu(id: { eq: $footerId }) {
      ...otherMenuFields
    }
    socialMenu: wpMenu(id: { eq: $socialMenuId }) {
      ...otherMenuFields
    }
    wpPost(id: { eq: $id }) {
      id
      databaseId
      title
      uri
      slug
      DisableFeatureImageInPost {
        disableFeatureImageInPost
      }
      categories {
        nodes {
          name
          slug
          language {
            locale
          }
        }
      }
      featuredImage {
        node {
          altText
          title
          slug
          imgixImage {
            gatsbyImageData(
              placeholder: BLURRED
              sizes: "(max-width: 768px) 420px, 640px"
              width: 640
              height: 640
              imgixParams: { fit: "crop", crop: "faces,entropy" }
              layout: FULL_WIDTH
            )
          }
        }
      }
      Lead {
        lead
        fieldGroupName
      }
      author {
        node {
          name
          slug
          hq_avatar {
            hqAvatar {
              id
              imgixImage {
                gatsbyImageData(
                  srcSetMaxWidth: 256
                  placeholder: BLURRED
                  sizes: "42px"
                  layout: FULL_WIDTH
                )
              }
            }
          }
          description
          multilingual_bio {
            multilingualBio {
              bioText
              locale
            }
          }
        }
      }
      dateGmt
      translationsAxios {
        slug
        language {
          locale
        }
      }
      language {
        locale
      }
      blocks {
        order
        saveContent
        __typename

        ... on WpCoreVideoBlock {
          attributes {
            ... on WpCoreVideoBlockAttributes {
              src
              poster
              muted
              loop
              controls
              autoplay
              playsInline
            }
          }
        }

        ... on WpAcfProductReviewBlock {
          productReview {
            individualProductReviewAnchorLinkId
            number
            productName
            productNameHeadingTag
            productReviewText
            productFeatures {
              featureName
              featureRating
              fieldGroupName
            }
            fieldGroupName
            pros {
              prosTitle
              prosList {
                proIcon
                proText
              }
            }
            cons {
              consTitle
              consList {
                conText
                conIcon
              }
            }
            button {
              fieldGroupName
              shopButton
              shopLink
              shopLinkOpenInNewTab
            }
            image {
              altText
              sourceUrl
              imgixImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: "(max-width: 768px) 420px, 640px"
                  layout: FULL_WIDTH
                  imgixParams: { auto: "format", q: 70, usm: 15 }
                )
              }
            }
          }
        }
        ... on WpAcfNumberedHeadingBlock {
          numberedHeading {
            numberedHeading
            numberedHeadingTitle
            numberedHeadingTitleTag
            fieldGroupName
            awardsList {
              awardDescriptionPreselection
              awardName
              fieldGroupName
            }
            numberedHeadingAnchorLinkId
          }
        }
        ... on WpAcfCallOutBulletListBlock {
          CallOutBulletList {
            fieldGroupName
            iconBulletPointList {
              fieldGroupName
              iconBulletPointListHeading
              iconBulletPointListIcon
              iconBulletPointListTextMultiParagraphSection {
                iconBulletPointListTextParagraph
              }
            }
          }
          ImageIconBulletList {
            fieldGroupName
            imageIconBulletPointList {
              fieldGroupName
              imageIconBulletPointListHeading
              imageIconBulletPointListTextMultiParagraphSection {
                imageIconBulletPointListTextParagraph
              }
              imageIconBulletPointListIcon {
                altText
                title
                slug
                imgixImage {
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 50
                    height: 50
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        ... on WpAcfCallOutHorizontalBlock {
          CallOutHorizontal {
            callOutBoxHorizontal {
              callOutBoxHorizontalHeading
              callOutBoxHorizontalIcon
              callOutBoxHorizontalText
              fieldGroupName
            }
          }
        }
        ... on WpCoreListBlock {
          saveContent
        }
        ... on WpAcfSimpleIconListBlock {
          dynamicContent
          originalContent
          SimpleIconList {
            simpleIconLists {
              simleIconListText
              simpleIconListIcon
            }
          }
        }
        ... on WpAcfNumberIconListBlock {
          dynamicContent
          originalContent
          SimpleNumberedIconList {
            simpleNumberedIconLists {
              simpleNumberedIconList
              simpleNumberedIconListText
            }
          }
        }
        ... on WpAcfAccordionBlock {
          dynamicContent
          originalContent
          Accordion {
            accordion {
              accordionTitle
              accordionTextMultiEntry {
                accordionText
                accordionTextAdvancedTocLink {
                  target
                  title
                  url
                }
              }
            }
          }
        }
        ... on WpAcfCallOutConversationBlock {
          dynamicContent
          CallOutConversation {
            conversation {
              conversationAnswer
              conversationQuestion
            }
          }
        }
        ... on WpAcfCustomImageBlock {
          dynamicContent
          originalContent
          CustomImage {
            customImageAttributionText
            fieldGroupName
            customImageTitleAndAltText {
              customImageTitleTag
              customImageAltText
            }
            customImageBlock {
              altText
              title
              slug
              mimeType
              sourceUrl
              imgixImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: "(max-width: 768px) 420px, 640px"
                  layout: FULL_WIDTH
                  imgixParams: { auto: "format", q: 70, usm: 15 }
                )
              }
            }
            customImageWebsiteAttributionOption {
              customImageWebsiteAttributionTextAndLink {
                target
                title
                url
              }
              customImageWebsiteAttributionTextAndLinkMakeNoFollow
            }
          }
        }
        ... on WpAcfCustomButtonBlock {
          dynamicContent
          originalContent
          customButton {
            customButton {
              customButtonLinkMakeNoFollow
              customButtonStyle
              customButtonTextAndLink {
                target
                title
                url
              }
            }
          }
        }
        ... on WpAcfNumberedHeadingWithImageBlock {
          dynamicContent
          originalContent
          numberedHeading {
            numberedHeading
            numberedHeadingTitle
            numberedHeadingTitleTag
            fieldGroupName
            awardsList {
              awardDescriptionPreselection
              awardName
              fieldGroupName
            }
            numberedHeadingAnchorLinkId
          }
          CustomImage {
            customImageAttributionText
            fieldGroupName
            customImageBlock {
              altText
              title
              slug
              imgixImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  sizes: "(max-width: 768px) 420px, 640px"
                  layout: FULL_WIDTH
                  imgixParams: { auto: "format", q: 70, usm: 15 }
                )
              }
            }
            customImageWebsiteAttributionOption {
              customImageWebsiteAttributionTextAndLink {
                target
                title
                url
              }
              customImageWebsiteAttributionTextAndLinkMakeNoFollow
            }
          }
        }
        ... on WpAcfImageQuoteBlock {
          dynamicContent
          originalContent
          ImageQuote {
            imageQuote {
              imageQuoteName {
                imageQuoteNameQuoteText
                imageQuoteNameText
                imageQuoteNameTitle
                imageQuoteNameTitleLinkUrl
                imageQuoteNameTitleLinkUrlMakeNoFollow
                imageQuoteNameTitleLinkUrlOpenNewTab
                imageQuoteNameImage {
                  imgixImage {
                    gatsbyImageData(
                      placeholder: BLURRED
                      sizes: "(max-width: 768px) 420px, 640px"
                      layout: FULL_WIDTH
                      imgixParams: { auto: "format", q: 70, usm: 15 }
                    )
                  }
                }
              }
            }
          }
        }
        ... on WpAcfPeopleListBlock {
          dynamicContent
          originalContent
          PeopleList {
            peopleListEnableSlider
            peopleList {
              peopleListGroup {
                peopleListBioText
                peopleListHyperlinks {
                  makeHeadingAndImageClickable
                  peopleListHyperlinkTextAndLink {
                    target
                    title
                    url
                  }
                  peopleListHyperlinkTextAndLinkMakeNoFollow
                }
                peopleListName
                peopleListSubheadingText
                peopleListTitle
                peopleListImage {
                  imgixImage {
                    gatsbyImageData(
                      placeholder: BLURRED
                      sizes: "(max-width: 768px) 210px, 320px"
                      layout: FULL_WIDTH
                      imgixParams: { auto: "format", q: 70, usm: 15 }
                    )
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTableOfContentsBlock {
          dynamicContent
          originalContent
          TableOfContents {
            fieldGroupName
            tableOfContents {
              tocAnchorLink
              tocText
            }
          }
        }
        ... on WpCoreHeadingBlock {
          dynamicContent
          originalContent
          attributes {
            ... on WpCoreHeadingBlockAttributes {
              anchor
              textAlign
            }
          }
        }
        ... on WpCoreHtmlBlock {
          saveContent
        }
        ... on WpAcfCustomVideoBlock {
          customVideo {
            customVideoUrl
            customVideoAllowFullscreen
            customVideoAutoplay
            customVideoKeyboardControls
            customVideoHideYtLogo
            customVideoControls
            customVideoLoop
            customVideoMuted
          }
        }
        ... on WpCoreTableBlock {
          attributes {
            ... on WpCoreTableBlockAttributes {
              align
              anchor
              body {
                cells {
                  align
                  content
                  scope
                  tag
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphPublisher
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
        }
        breadcrumbs {
          url
          text
        }
        cornerstone
        schema {
          pageType
          articleType
          raw
        }
        readingTime
      }
      AdvancedSEOSettings {
        advancedSeoSettings {
          customCanonical
          noindexPost
        }
      }
    }
  }
`;
